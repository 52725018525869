import React, {useEffect, useState} from "react";
import {useParams, withRouter} from "react-router-dom";
import "./content.css";
import getProductById from "../../httpRequests/getProductById";
import Button from "../Button/Button";
import useWindowDimensions from "../../util/useWindowDimensions";
import useQuery from "../../util/useQuery";
import labels from "../../i18n/labels";

const Content = (props) => {
    const { id } = useParams();
    const langParam = useQuery().get("lang");
    const [product, setProduct] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    // eslint-disable-next-line
    const [notFound, setNotFound] = useState(false);

    const { width } = useWindowDimensions();
    const maxWidth = 420;
    const minWidth = 340;
    let modelViewerWidthRaw = minWidth
    const calculatedWidth = width - 32;
    if (calculatedWidth <= maxWidth) {
        modelViewerWidthRaw = calculatedWidth;
    } else if (calculatedWidth <= minWidth) {
        modelViewerWidthRaw = minWidth
    } else {
        modelViewerWidthRaw = maxWidth
    }
    const modelViewerWidth = `${modelViewerWidthRaw}px`;
    const modelViewerHeight = modelViewerWidth;

    useEffect(()=> {
        getProductById(id)
            .then((res) => {
                setProduct(res.data);
                setHasLoaded(true);
                setNotFound(false);
            })
            .catch((err) => {
                setNotFound(true);
                setHasLoaded(true);
                props.history.push(`/404${langParam ? `?lang=${langParam}` : ""}`);
            });
        // eslint-disable-next-line
    }, []);

    return(
        <div className="content-container">
            <div className="content-title" style={{ width: modelViewerWidth }}>
                {id.substring(0,8)}
            </div>
            <div className="object-container" style={{ height: modelViewerHeight, width: modelViewerWidth, backgroundColor: "#eeeeee", borderRadius: "8px" }}>
                {product.androidModel ?
                    <div>
                        <model-viewer
                            src={product.androidModel.publicLink}
                            alt="A 3D model"
                            shadow-intensity="1"
                            camera-controls
                            auto-rotate
                            autoplay
                            style={{height: modelViewerHeight, width: modelViewerWidth,}}
                        ></model-viewer>
                    </div>
                    :
                    null
                }
            </div>
            <div className="button-container">
                <Button
                    loadingText={labels.loadingText.hasOwnProperty(langParam) ?
                        labels.loadingText[langParam] : labels.loadingText.en
                    }
                    title={labels.buttonText.hasOwnProperty(langParam) ?
                        labels.buttonText[langParam] : labels.buttonText.en
                    }
                    width={modelViewerWidth}
                    hasLoaded={hasLoaded}
                    onClick={() => {

                    }}
                />
                <div className="ar-button-help-container">
                    <div className="ar-button-help-text">
                        <a
                            className="ar-button-help-text"
                            href={labels.arWizardLink.hasOwnProperty(langParam) ?
                                labels.arWizardLink[langParam] : labels.arWizardLink.en
                            }
                            // eslint-disable-next-line
                            target="_blank"
                        >
                            {labels.howItWorksText.hasOwnProperty(langParam) ?
                                labels.howItWorksText[langParam] : labels.howItWorksText.en
                            }
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withRouter(Content);
