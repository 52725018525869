import React from "react";
import Lottie from "react-lottie";
import "./button.css";
import { ReactComponent as ArLogo } from "../../assets/svg/ARwidget_ic_AR_small.svg";
import * as animationData from "../../assets/lottie/anim_arLoader.json";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Button = (props) => {
  const { hasLoaded, onClick, width, notSupportedBrowser, title, loadingText, approvalButton, navigationButton } = props;
  if (notSupportedBrowser) {
    const referrer = (window.location !== window.parent.location)
        ? document.referrer
        : document.location.href;
    return (
    <a
        href={`https://arwidget.lab.mobi/incompatible-browser/?redirect=${referrer}`}
        // eslint-disable-next-line
        target="_blank"
        style={{ textDecoration: 'none'}}
    >
      <div
          style={{ width }}
          id="ar-button"
          className={`ar-button${hasLoaded ? "" : " ar-button-inactive"}`}
      >
        <ArLogo width="24" height="24" />
        <div id="ar-button-text" className="ar-button-text" style={{ textDecoration: 'none'}}>
          {title}
        </div>
      </div>
    </a>
    )
  }
  if (navigationButton) {
    return (
        <button
            id="ar-button"
            className={`ar-button${hasLoaded ? "" : " ar-button-inactive"}`}
            onClick={onClick}
            style={ approvalButton ? { width: "100%" } : { width }}
        >
          <div id="ar-button-text" className="ar-button-text">
            {hasLoaded ? title : loadingText}
          </div>
        </button>
    )
  }
  return (
    <button
      id="ar-button"
      className={`ar-button${hasLoaded ? "" : " ar-button-inactive"}`}
      onClick={onClick}
      style={ approvalButton ? { width: "100%" } : { width }}
    >
      {hasLoaded ? (
        <ArLogo width="24" height="24" />
      ) : (
        <Lottie
          options={lottieOptions}
          height={24}
          width={24}
          style={{ margin: 0, padding: 0 }}
        />
      )}
      <div id="ar-button-text" className="ar-button-text">
        {hasLoaded ? title : loadingText}
      </div>
    </button>
  );
};

export default Button;
