export const AR_WIDGET_INIT = "AR_WIDGET_INIT";
export const INSERT_MODAL = "INSERT_MODAL";
export const OPEN_MODAL_ON_DESKTOP = "OPEN_MODAL_ON_DESKTOP";
export const CLOSE_MODAL_ON_DESKTOP = "CLOSE_MODAL_ON_DESKTOP";
export const OPEN_MODEL_ON_MOBILE = "OPEN_MODEL_ON_MOBILE";

export const LOCATION = "LOCATION";
export const RESIZE = "RESIZE";
export const OPEN_EXTERNAL_REDIRECT = "OPEN_EXTERNAL_REDIRECT";
export const INSERT_AND_OR_OPEN_DEVICE_NOT_SUPPORTED_MODAL = "INSERT_AND_OR_OPEN_DEVICE_NOT_SUPPORTED_MODAL";

export const AUDIO_APPROVED = "AUDIO_APPROVED";
export const OPEN_AUDIO_FROM_QR_CONFIRMATION_MODAL = "OPEN_AUDIO_FROM_QR_CONFIRMATION_MODAL";
export const INSERT_MODEL_OPEN_APPROVAL_MODAL = "INSERT_MODEL_OPEN_APPROVAL_MODAL";
export const OPEN_MODEL_APPROVAL_MODAL = "OPEN_MODEL_APPROVAL_MODAL";
export const CLOSE_MODEL_APPROVAL_MODAL = "CLOSE_MODEL_APPROVAL_MODAL";
export const CLOSE_DEVICE_NOT_SUPPORTED_MODAL = "CLOSE_DEVICE_NOT_SUPPORTED_MODAL";
