import React, { useEffect } from "react";
import QRCode from "qrcode.react";
import Lottie from "react-lottie";
import "./modal.css";
import { ReactComponent as CloseLogo } from "../../assets/svg/ARwidget_ic_close.svg";
import * as animationData from "../../assets/lottie/anim_QRscan.json";
import {
  AR_WIDGET_INIT,
} from "../../constants/crossDomainMessages";
import { useParams } from "react-router-dom";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Modal = (props) => {
  const { onClose, product, parentWindow, learnToScanText, title, helpLink } = props;
  const { id } = useParams();
  const url = parentWindow ? parentWindow.href : document.referrer;
  const qrCode =
    url.includes("iglucraft.com") === false
      ? `${url}?openArWidgetFromQr=true&productId=${product.resourceId}#${product.resourceId}`
      : `https://www.iglucraft.com/en/saunas/single?openArWidgetFromQr=true&productId=${product.resourceId}#${product.resourceId}`;

  useEffect(() => {
    window.parent.postMessage(
      JSON.stringify({ type: AR_WIDGET_INIT, id: `ar-modal-${id}` }),
      "*"
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="ar-modal">
      <div className="ar-modal-inner">
        <div className="ar-modal-close-container">
          <div style={{ display: "flex" }} />
          <div className="ar-modal-close">
            <CloseLogo width="48" height="48" onClick={onClose} />
          </div>
        </div>
        <div className="ar-modal-content-container">
          <Lottie
            options={lottieOptions}
            height={128}
            width={176}
            style={{ margin: 0, padding: 0 }}
          />
          <div className="ar-modal-title">{title}</div>
          <div className="ar-modal-help">
            <a
              href={helpLink}
                // eslint-disable-next-line
              target="_blank"
              className="ar-modal-help-text"
            >
              {learnToScanText}
            </a>
          </div>
          <div className="ar-modal-qr">
            <div>
              <QRCode style={{ padding: "32px" }} size={180} value={qrCode} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
