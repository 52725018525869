export default {
    buttonText: {
        et: "Vaatle liitreaalsuses",
        en: "Experience in reality",
    },
    fallbackButtonText: {
        et: "Tagasi",
        en: "Go back",
    },
    scanHelperText: {
        et: "Kuidas skannida",
        en: "Learn how to scan",
    },
    qrPromptText: {
        et: "Skannige kood oma nutitelefoni kaamerarakendusega",
        en: "Scan the code with your mobile phone camera application",
    },
    qrEmbedPromptText: {
        et: "Skaneeri QR-koodi nutiseadmega",
        en: "Scan the code with your mobile phone"
    },
    howItWorksText: {
        et: "Kuidas see töötab?",
        en: "How it works?",
    },
    arWizardLink: {
        et: "https://lab.mobi/ar-wizard-ee",
        en: "https://lab.mobi/ar-wizard",
    },
    arHelpLink: {
        et: "https://lab.mobi/ar-help-ee",
        en: "https://lab.mobi/ar-help",
    },
    loadingText: {
        et: "Laeb...",
        en: "Loading...",
    },
    makeSureToTurnOnSound: {
        et: "Lülitage kindlasti heli sisse",
        en: "Make sure to turn on the sound",
    },
    open3dObject: {
        et: "Vajuta nuppu liitreaalsuse avamiseks",
        en: "Click the button to open 3D object",
    },
    notFoundText: {
        et: "Sisu ei leitud",
        en: "Content not found"
    }
}
