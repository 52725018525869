import React from "react";
import {useParams, withRouter} from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./selfConatined.css";
import QRCode from "qrcode.react";
import { ReactComponent as ArIcon } from "../../assets/svg/AR_icon_text.svg";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";
import { ReactComponent as ArIllustration } from "../../assets/svg/AR_illustration.svg";
import Button from "../Button/Button";
import useQuery from "../../util/useQuery";
import labels from "../../i18n/labels";

const SelfContained = (props) => {
    const { id } = useParams();
    const langParam = useQuery().get("lang");

    const url = window.location.origin;
    const qrCode = `${url}/content/${id}${langParam ? `?lang=${langParam}` : '' }`;

    return(
        <div className="self-contain-container">
            <div className={isMobile? "self-contain-inner-mobile" : "self-contain-inner"}>
                {isMobile ?
                    <div className="self-contain-mobile">
                        <ArIllustration width={220} height={171} />
                        <div className="self-contain-button">
                            <Button
                                loadingText={"Loading..."}
                                title={"Vaatle liitreaalsuses"}
                                width="320px"
                                hasLoaded={true}
                                onClick={()=> {
                                    const win = window.open(qrCode, '_blank');
                                    if (win != null) {
                                        win.focus();
                                    }
                                }}
                            />
                        </div>
                        <div className="self-contain-ar-icon-mobile">
                            <ArIcon width={24} height={38}/>
                        </div>

                    </div>
                    :
                    <div className="self-contain-desktop">
                        <div className="self-contain-title">
                            {labels.buttonText.hasOwnProperty(langParam) ?
                                labels.buttonText[langParam] :
                                labels.buttonText["en"]
                            }
                        </div>
                        <div className="self-contain-prompt">
                            {labels.qrEmbedPromptText.hasOwnProperty(langParam) ?
                                labels.qrEmbedPromptText[langParam] :
                                labels.qrEmbedPromptText["en"]
                            }
                        </div>
                        <div className="self-contain-qr">
                            <QRCode size={159} value={qrCode}/>
                        </div>
                        <div className="self-contain-ar-icon">
                            <ArIcon width={24} height={38}/>
                        </div>
                        <div className="self-contain-arrow">
                            <Arrow width={28} height={48}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default withRouter(SelfContained);

