import React from "react";
import Lottie from "react-lottie";
import "./approvalModal.css";
import { ReactComponent as CloseLogo } from "../../assets/svg/ARwidget_ic_close.svg";
import * as animationData from "../../assets/lottie/anim_audio.json";
import * as animationDataAndroid from "../../assets/lottie/anim_QRscan.json";


const Modal = (props) => {
    const { onClose, title, button, androidApproval } = props;

    return (
        <div className="approval-ar-modal">
            <div className="approval-ar-modal-inner">
                <div className="approval-ar-modal-close-container">
                    <div style={{ display: "flex" }} />
                    <div className="approval-ar-modal-close">
                        <CloseLogo width="48" height="48" onClick={onClose} />
                    </div>
                </div>
                <div className="approval-ar-modal-content-container">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: androidApproval ? animationDataAndroid : animationData,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        height={128}
                        width={176}
                        style={{ margin: 0, padding: 0 }}
                    />
                    <div className="approval-ar-modal-title">{title}</div>
                    {/*
                    <div className="approval-ar-modal-help">
                        <a
                            href={helpLink}
                            target="_blank"
                            className="approval-ar-modal-help-text"
                        >
                            {learnToScanText}
                        </a>
                    </div>
                    */}
                </div>
                <div className="approval-ar-modal-button-container">
                    {button}
                </div>
            </div>
        </div>
    );
};

export default Modal;
