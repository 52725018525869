import axios from "axios";
import { BASE_URL } from "../constants/apiUrls";

const http = axios.create({
  baseURL: BASE_URL,
  timeout: 40000,
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    // Do something with response
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // alert(SOMETHING_WENT_WRONG);
    return Promise.reject(error);
  }
);
export default http;
