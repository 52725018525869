import React from "react";
import "./style.css";

const NoMatch = (props) => {
  const { onClick, width, text } = props;
  return (
    <div>
      <button
        onClick={onClick}
        style={{ width }}
        id="not-found-button"
        className="not-found-button"
      >
        <div id="not-found-button-text" className="not-found-button-text">
          {text ? text : "Resource not found"}
        </div>
      </button>
    </div>
  );
};

export default NoMatch;
