import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import "./deviceNotSupportedModal.css";
import useQuery from "../../util/useQuery";
import getProductById from "../../httpRequests/getProductById";
import {ReactComponent as CloseLogo} from "../../assets/svg/ARwidget_ic_close.svg";
import ReactGa from "../../util/ga";

const ThreeDimViewModal = (props) => {

    const id = useQuery().get("id");
    const [showInfo, setShowInfo] = useState(true);
    const [product, setProduct] = useState(false);
    // eslint-disable-next-line
    const [hasLoaded, setHasLoaded] = useState(false);
    // eslint-disable-next-line
    const [notFound, setNotFound] = useState(false);
    console.log(id);
    document.body.style.overflow = "hidden"

    useEffect(()=> {
        getProductById(id)
            .then((res) => {
                setProduct(res.data);
                setHasLoaded(true);
                setNotFound(false);
            })
            .catch((err) => {
                setNotFound(true);
                setHasLoaded(true);
            });
        ReactGa.event({
            category: '3D Model open',
            action: 'AR fallback model was opened',
            label: id,
        });
        // eslint-disable-next-line
    }, []);

    console.log(product);
    return (
        <div className="three-dim-container" style={{ minHeight: "100vh", minWidth: "100%", backgroundColor: "#eeeeee" }}>
            {product.androidModel ?
                <div>
                    <model-viewer
                        src={product.androidModel.publicLink}
                        alt="A 3D model"
                        shadow-intensity="1"
                        camera-controls
                        auto-rotate
                        autoplay
                        style={{width: '100%', height: '100vh'}}
                    ></model-viewer>
                </div>
                :
                null
            }
            <div style={{ borderRadius: "50%", width: "48px", height: "48px", position: "absolute", top: "16px", right: "16px", backgroundColor: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CloseLogo width="42px" height="42px" onClick={()=> props.history.goBack()} />
            </div>
            {showInfo ?
                <div style={{
                    minWidth: "100%",
                    height: "80px",
                    position: "absolute",
                    left: "0px",
                    bottom: "0px",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}>
                    <div style={{ padding: "24px", display: "flex", flexDirection: "column"}}>
                        <div style={{ fontSize: "16px", lineHeight: "19px"}}>
                        Your device does not support augmented reality
                        </div>
                        <a
                            href="https://lab.mobi/mars/experience-in-reality/wizard#supported-devices"
                            // eslint-disable-next-line
                            target="_blank"
                            className="ar-modal-help-text"
                            style={{ alignSelf: "flex-start" }}
                        >
                            Show supported devices
                        </a>
                    </div>
                    <div style={{ padding: "24px"}}>
                        <CloseLogo width="32px" height="32px" onClick={() => setShowInfo(false)}/>
                    </div>
                </div>
                :
                null
            }

        </div>
    );
};

export default withRouter(ThreeDimViewModal);
