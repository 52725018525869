/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useRef, useState} from "react";
import {useParams, withRouter} from "react-router-dom";
import {isAndroid, isChrome, isChromium, isIOS, isMobile,} from "react-device-detect";
import {Howl, Howler} from 'howler';
import "./root.css";
import ReactGa from "../../util/ga";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import getProductById from "../../httpRequests/getProductById";
import useQuery from "../../util/useQuery";
import {NO_AVAILABLE_MODEL} from "../../constants/globalStrings";
import {getParentUrl, getUrlParams} from "../../util/parentUrl";
import stringsData from "../../i18n/strings";
import {
  AR_WIDGET_INIT, CLOSE_DEVICE_NOT_SUPPORTED_MODAL,
  CLOSE_MODAL_ON_DESKTOP, CLOSE_MODEL_APPROVAL_MODAL,
  INSERT_MODAL, INSERT_MODEL_OPEN_APPROVAL_MODAL, OPEN_EXTERNAL_REDIRECT,
  OPEN_MODAL_ON_DESKTOP,
  OPEN_MODEL_ON_MOBILE
} from "../../constants/crossDomainMessages";
import NoMatch from "../NoMatch/NoMatch";
import ApproveModelOpenModal from "../Modal/ApproveModelOpenModal";
import usePrevious from "../../util/usePrevious";
import DeviceNotSupportedModal from "../Modal/DeviceNotSupportedModal";

const Root = (props) => {
  const { id } = useParams();
  const parentUrl = getParentUrl();
  const parentParams = getUrlParams(parentUrl);

  const [soundPlaying, setSoundPlaying] = useState(false);
  const [modalLoaded, setModalLoaded] = useState(false);
  const [modelOpenApprovalModalLoaded, setModelOpenApprovalModalLoadedLoaded] = useState(false);
  const [requestCount, setRequestCount] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [qrOpened, setQrOpened] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [modal, setModal] = useState(false);
  // eslint-disable-next-line
  const [approvalModal, setApprovalModal] = useState(false);
  // eslint-disable-next-line
  const [deviceNotSupportedModal, setDeviceNotSupportedModal] = useState(false);
  const [product, setProduct] = useState(false);
  const iosModel = useRef({});
  const androidModel = useRef({});
  const [androidModelOpen, setAndroidModelOpen] = useState(false);

  const frameUrl = window.location.origin + window.location.pathname;
  const openedModal = useQuery().get("openedModal");
  const androidOpenApprovalModal = useQuery().get("androidOpenApproval");
  const modelOpenApprovalModal = useQuery().get("modelOpenApproval");
  const incompatibleBrowserOpenModal = useQuery().get("incompatibleBrowserOpenModal");
  const openExternal = useQuery().get("openExternal");
  const langParam = useQuery().get("lang");
  const audioParam = useQuery().get("audio");
  const strings = langParam === "et" ? stringsData.et : stringsData.en;

  const cantOpenAndroidModelExternalLink = "https://arwidget.lab.mobi/not-available";

  const pageVisible = props.isVisible;

  const prevPageVisible = usePrevious(pageVisible);
  const prevAndroidModelOpen = usePrevious(androidModelOpen);

  // TODO when you quit browser from ar view, sound is not killed. Handle case.
  useEffect(() => {
    if (pageVisible && soundPlaying) {
      setSoundPlaying(false);
      Howler.unload();
      if (isIOS && modelOpenApprovalModal === "true") {
        closeApprovalModal();
      }
    }
    // eslint-disable-next-line
  }, [pageVisible]);

  useEffect(() => {
    if (isAndroid) {
      /*
      console.log(`pagevisible changed: ${prevPageVisible !== pageVisible}`);
      console.log(`android model open changed: ${prevAndroidModelOpen !== androidModelOpen}`);
      console.log(`page visible ${pageVisible}`);
      console.log(`android open ${androidModelOpen}`);
      console.log(`PREVIOUS page visible ${prevPageVisible}`);
      console.log(`PREVIOUS android open ${prevAndroidModelOpen}`);
      */

      if(prevPageVisible && !pageVisible && androidModelOpen) {
        // console.log("android could be opened");
        return;
        // pass;
      } else if (androidModelOpen && prevAndroidModelOpen && !prevPageVisible && pageVisible) {
        // console.log("android model could be closed");
        setAndroidModelOpen(false);
        return;
      } else if (pageVisible && androidModelOpen && prevPageVisible && !prevAndroidModelOpen) {
        console.log("android model could not be opened");
        setAndroidModelOpen(false);
        /*
        const modalId = `ar-modal-device-not-supported-${id}`;
        const modalFrame = `<iframe id="ar-modal-device-not-supported-${id}" src="${frameUrl}?incompatibleBrowserOpenModal=true&lang=${langParam}" style="display:block; position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" frameborder="0" ></iframe>`;
        window.parent.postMessage(
            JSON.stringify({ type: INSERT_AND_OR_OPEN_DEVICE_NOT_SUPPORTED_MODAL, modalId, modalFrame }),
            "*"
        );
        */
        if (audioParam === true || audioParam === "true") {
          playSound(product.audioResource.publicLink);
        }
        window.parent.postMessage(JSON.stringify({ type: OPEN_EXTERNAL_REDIRECT, url: `https://arwidget.lab.mobi/object-model?id=${id}` }), "*");

        return;
      }
      return;
    }
    // eslint-disable-next-line
  }, [pageVisible, androidModelOpen]);



  useEffect(() => {
    if (openedModal === "true") {
      ReactGa.event({
        category: 'AR Widget init',
        action: 'Modal widget init',
        label: id,
      });
    } else {
      ReactGa.pageview(`/products/${id}`);
      ReactGa.event({
        category: 'AR Widget init',
        action: 'Button widget init',
        label: id,
      });
    }
    const frameId = openedModal !== "true" ? id : `ar-modal-${id}`;
    window.parent.postMessage(
      JSON.stringify({ type: AR_WIDGET_INIT, id: frameId }),
      "*"
    );
    if (!modalLoaded) {
      if (!isMobile && (openedModal === null || openedModal !== "true")) {
        const modalFrame = `<iframe id="ar-modal-${id}" src="${frameUrl}?openedModal=true&lang=${langParam}" style="display:none; position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" frameborder="0" ></iframe>`;
        window.parent.postMessage(
            JSON.stringify({
              type: INSERT_MODAL,
              id,
              modalId: `ar-modal-${id}`,
              modalFrame,
            }),
            "*"
        );
        setModalLoaded(true);
      }
    }
    if (openExternal === null || openExternal === "null") {
      getProductById(id)
          .then((res) => {
            setProduct(res.data);
            setHasLoaded(true);
          })
          .catch((err) => {
            setNotFound(true);
            setHasLoaded(true);
          });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    if (notFound && requestCount < 8) {
      if (openedModal === "true") {
        console.log(`${id} Not found. Retrying attempt ${requestCount}.`);
      }

      onNotFoundClick();
      setRequestCount(requestCount + 1);
    }
    // eslint-disable-next-line
  }, [notFound, requestCount])

  const onNotFoundClick = () => {
    const frameId = openedModal !== "true" ? id : `ar-modal-${id}`;
    window.parent.postMessage(
      JSON.stringify({ type: AR_WIDGET_INIT, id: frameId }),
      "*"
    );
    setNotFound(false);
    setHasLoaded(false);
    getProductById(id)
      .then((res) => {
        setProduct(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        setNotFound(true);
        setHasLoaded(true);
      });
  };

  const { parentWindow } = props;
  const hasOpenFromQrParam = parentWindow
    ? parentWindow.search.includes("openArWidgetFromQr")
    : false;
  const hasProductId = parentWindow ? parentWindow.search.includes(id) : false;

  useEffect(() => {
    if (!qrOpened){
      if (isMobile && product !== false && hasOpenFromQrParam && hasProductId) {
        ReactGa.event({
          category: 'QR redirect',
          action: '3D Model was opened',
          label: id,
        });
        // console.log("open AR on mobile now");
        if (audioParam === true || audioParam === "true") {
          if (!modelOpenApprovalModalLoaded) {
            if (isMobile && (modelOpenApprovalModal === null || modelOpenApprovalModal !== "true")) {
              const approveModalFrame = `<iframe id="ar-modal-approve-model-${id}" src="${frameUrl}?modelOpenApproval=true&lang=${langParam}&audio=${audioParam === true || audioParam === "true" ? "true" : "false"}" style="display:block; position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" frameborder="0" ></iframe>`;
              window.parent.postMessage(
                  JSON.stringify({
                    type: INSERT_MODEL_OPEN_APPROVAL_MODAL,
                    id,
                    modalId: `ar-modal-approve-model-${id}`,
                    approveModalFrame,
                  }),
                  "*"
              );
              setModelOpenApprovalModalLoadedLoaded(true);
            }
          }
        } else if (isAndroid) {
          if (!modelOpenApprovalModalLoaded) {
            if (isMobile && (modelOpenApprovalModal === null || modelOpenApprovalModal !== "true")) {
              const approveModalFrame = `<iframe id="ar-modal-approve-model-${id}" src="${frameUrl}?androidOpenApproval=true&lang=${langParam}" style="display:block; position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" frameborder="0" ></iframe>`;
              window.parent.postMessage(
                  JSON.stringify({
                    type: INSERT_MODEL_OPEN_APPROVAL_MODAL,
                    id,
                    modalId: `ar-modal-approve-model-${id}`,
                    approveModalFrame,
                  }),
                  "*"
              );
              setModelOpenApprovalModalLoadedLoaded(true);
            }
          }
        } else {
          buttonClick(product);
        }
        setQrOpened(true);
      }
    }
    // eslint-disable-next-line
  }, [product, parentParams]);

  useEffect(() => {
    if (!qrOpened){
      if (modalLoaded && !isMobile && product !== false && hasOpenFromQrParam && hasProductId) {
        ReactGa.event({
          category: 'QR redirect',
          action: '3D Model was opened',
          label: id,
        });
        // console.log("open AR on mobile");
        buttonClick(product);
        setQrOpened(true);
      }
    }
    // eslint-disable-next-line
  }, [product, parentParams, modalLoaded]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasLoaded(true)
    }, 8000);
    return () => clearTimeout(timer);
  }, [hasLoaded]);

  const playSound = (url) => {
    const sound = new Howl({ src: url, html5: true, loop: true, });
    sound.play();
    setSoundPlaying(true);
  }

  const buttonClick = (product) => {
    if (openExternal === null || openExternal === "null") {
      ReactGa.event({
        category: 'AR Button',
        action: 'Widget button was clicked',
        label: id,
      });
      if (product) {
        if (isMobile) {
          ReactGa.event({
            category: 'AR Button',
            action: '3D Model was opened',
            label: id,
          });
          window.parent.postMessage(
              JSON.stringify({ type: OPEN_MODEL_ON_MOBILE, id }),
              "*"
          );
          if (audioParam === "true" && modelOpenApprovalModal === "true") {
            // closeApprovalModal();
          }
          if (isIOS) {
            if (product.iosModel) {
              setHasLoaded(false)
              if (isChrome || isChromium){
                setHasLoaded(false);
              }
              ReactGa.event({
                category: '3D Model open',
                action: 'iOS model was opened',
                label: id,
              });
              // console.log("opening ios");



              if (audioParam === true || audioParam === "true") {
                playSound(product.audioResource.publicLink);
              }
              iosModel.current.click();
            } else {
              alert(NO_AVAILABLE_MODEL);
            }
          }
          if (isAndroid) {
            if (product.androidModel) {
              ReactGa.event({
                category: '3D Model open',
                action: 'Android model was opened',
                label: id,
              });
              // console.log("opening android");
              if (modelOpenApprovalModal === "true" || androidOpenApprovalModal === "true") {
                closeApprovalModal();
              }
              // TODO formatting
              console.log("opening android");
              try {
                androidModel.current.click();
                setTimeout(() => {
                  setAndroidModelOpen(true);
                }, 500);
              } catch (e) {
                console.log("android open failed")
              }
            } else {
              alert(NO_AVAILABLE_MODEL);
            }
          }
        } else {
          ReactGa.modalview('/ar-modal');
          ReactGa.event({
            category: 'AR Modal',
            action: 'AR Modal was opened',
            label: id,
          });
          window.parent.postMessage(
              JSON.stringify({
                type: OPEN_MODAL_ON_DESKTOP,
                id,
                modalId: `ar-modal-${id}`,
              }),
              "*"
          );
          setModal(!modal);
        }
      }
    } else {
      window.open(openExternal, '_blank');
    }
  };

  const closeModal = () => {
    ReactGa.event({
      category: 'AR Modal',
      action: 'AR Modal was closed',
      label: id,
    });
    window.parent.postMessage(
      JSON.stringify({
        type: CLOSE_MODAL_ON_DESKTOP,
        id,
        modalId: `ar-modal-${id}`,
      }),
      "*"
    );
    setModal(false);
  };

  const closeApprovalModal = () => {
    window.parent.postMessage(
        JSON.stringify({
          type: CLOSE_MODEL_APPROVAL_MODAL,
          id,
          modalId: `ar-modal-approve-model-${id}`,
        }),
        "*"
    );
    setApprovalModal(false);
  };

  const closeDeviceNotSupportedModal = () => {
    window.parent.postMessage(
        JSON.stringify({
          type: CLOSE_DEVICE_NOT_SUPPORTED_MODAL,
          id,
          modalId: `ar-modal-device-not-supported-${id}`,
        }),
        "*"
    );
    setDeviceNotSupportedModal(false);
  };

  return (
    <div>
      {openedModal !== "true" && modelOpenApprovalModal !== "true" && androidOpenApprovalModal !== "true" && incompatibleBrowserOpenModal !== "true" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!notFound && props.notFbBrowser ? (
            <Button
                loadingText={strings.loadingText}
                title={strings.buttonText}
              width={props.width}
              hasLoaded={hasLoaded}
              onClick={() => buttonClick(product)}
            />
          ) : null}
          {notFound && props.notFbBrowser ? (
            <NoMatch
              onClick={() => {
                // console.log("Try again");
                onNotFoundClick();
              }}
              width={props.width}
              text="Resource not found"
            />
          ) : null}
          {!props.notFbBrowser ? (
            <Button
                loadingText={strings.loadingText}
                title={strings.buttonText}
              width={props.width}
              hasLoaded={true}
              id={id}
              notSupportedBrowser={true}
              parentData={parentWindow}
              onClick={() => {
                ReactGa.event({
                  category: '3D model exception',
                  action: 'Unsupported web browser',
                  label: id,
                });
                alert(
                  "Unfortunately AR is not supported in this browser. Please open the web link in Safari."
                );
              }}
            />
          ) : null}
          <div className="ar-button-help-container">
            <div className="ar-button-help-text">
              <a
                className="ar-button-help-text"
                href={strings.arWizardLink}
                  // eslint-disable-next-line
                target="_blank"
              >
                {strings.howItWorksText}
              </a>
            </div>
          </div>
        </div>
      ) : null}
      {openedModal === "true" ? (
        <Modal
            learnToScanText={strings.scanHelperText}
            title={strings.qrPromptText}
            helpLink={strings.arHelpLink}
          parentWindow={parentWindow}
          product={product}
          onClose={() => closeModal()}
        />
      ) : null}

      {/*
      {isAndroid && androidOpenApprovalModal ==="true" && modelOpenApprovalModal !== "true" ? (
          <ApproveModelOpenModal
              learnToScanText={strings.scanHelperText}
              helpLink={strings.arHelpLink}
              parentWindow={parentWindow}
              product={product}
              androidApproval
              title="Click the button to open 3D model"
              onClose={() => closeApprovalModal()}
              button={
                <Button
                    loadingText={strings.loadingText}
                    title={strings.buttonText}
                    width={props.width}
                    hasLoaded={hasLoaded}
                    onClick={() => buttonClick(product)}
                />
              }

          />
      ) : null}
      */}

      {modelOpenApprovalModal === "true" && props.notFbBrowser ? (
          <ApproveModelOpenModal
              learnToScanText={strings.scanHelperText}
              helpLink={strings.arHelpLink}
              parentWindow={parentWindow}
              product={product}
              title={strings.makeSureToTurnOnSound}
              onClose={() => closeApprovalModal()}
              button={
                <Button
                    loadingText={strings.loadingText}
                    title={strings.buttonText}
                    width={props.width}
                    hasLoaded={hasLoaded}
                    approvalButton
                    onClick={() => buttonClick(product)}
                />
              }

          />
      ) : null}
      {androidOpenApprovalModal === "true" && modelOpenApprovalModal !== "true" && incompatibleBrowserOpenModal !== "true" && isAndroid && props.notFbBrowser ? (
          <ApproveModelOpenModal
              androidApproval={true}
              learnToScanText={strings.scanHelperText}
              helpLink={strings.arHelpLink}
              parentWindow={parentWindow}
              product={product}
              title={strings.open3dObject}
              onClose={() => closeApprovalModal()}
              button={
                <Button
                    loadingText={strings.loadingText}
                    title={strings.buttonText}
                    width={props.width}
                    hasLoaded={hasLoaded}
                    approvalButton
                    onClick={() => buttonClick(product)}
                />
              }

          />
      ) : null}
      {incompatibleBrowserOpenModal === "true" && androidOpenApprovalModal !== "true" && modelOpenApprovalModal !== "true" && isAndroid ? (
          <DeviceNotSupportedModal id={id} onClose={() => closeDeviceNotSupportedModal()} />
      ) : null}
      {isIOS && product.iosModel ? (
        <a
          ref={iosModel}
          id="ar-ios-model"
          rel="ar"
          href={product.iosModel.publicLink}
        >
          <img src=" " />
        </a>
      ) : null}
      {isAndroid && product.androidModel ? (
          <a
              ref={androidModel}
              id="ar-android-model"
              rel="ar"
              href={`intent://arvr.google.com/scene-viewer/1.0?file=${product.androidModel.publicLink}${audioParam === true || audioParam === "true" ? `&sound=${product.audioResource.publicLink}` : ""}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${cantOpenAndroidModelExternalLink};end;`}
          >
            <img src=" " />
          </a>
      ) : null}
      {modelOpenApprovalModal === "true" &&  isIOS && product.iosModel ? (
          <a
              ref={iosModel}
              id="ar-ios-model-open-from-approval"
              rel="ar"
              href={product.iosModel.publicLink}
          >
            <img src=" " />
          </a>
      ) : null}
    </div>
  );
};

export default withRouter(Root);
