import React, { useEffect } from "react";
import {withRouter} from "react-router-dom";
import {getUA} from "react-device-detect";
import "./deviceNotSupportedModal.css";
import {ReactComponent as CloseLogo} from "../../assets/svg/ARwidget_ic_close.svg";
import IncompatibleBrowserStep1 from "../../assets/img/IncompatibleBrowserStep1.png";
import IncompatibleBrowserStep2 from "../../assets/img/IncompatibleBrowserStep2.png";
import useQuery from "../../util/useQuery";

const IncompatibleBrowserModal = (props) => {

    const redirect = useQuery().get("redirect");
    useEffect(() => {
        const isFB =
            getUA.includes("FBAN") ||
            getUA.includes("FBAV") ||
            getUA.includes("FBBV") ||
            getUA.includes("FBDV") ||
            getUA.includes("FBMD") ||
            getUA.includes("Instagram") ||
            getUA.includes("instagram") ||
            getUA.includes("LinkedIn");
        if (redirect && !isFB) {
            window.location.replace(redirect);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="ar-fallback">
            <div className="ar-fallback-inner">
                <div className="ar-fallback-close-container">
                    <div style={{ display: "flex" }} />
                    <div className="ar-fallback-close">
                        <CloseLogo width="48" height="48" onClick={() => props.history.goBack()} />
                    </div>
                </div>
                <div style={{ marginBottom: "58px"}} className="ar-fallback-incompatible-browser-content-container">
                    <div className="ar-fallback-title">Please open the web link in Safari</div>

                    <div className="ar-fallback-description-text">
                        <div>
                            1.&nbsp;
                        </div>
                        <div>
                        On the bottom of your screen, click the Share button.
                        </div>
                    </div>
                    <img className="ar-fallback-incompatible-browser-img" src={IncompatibleBrowserStep1} alt="incompatible-browser-step1" />

                    <div className="ar-fallback-description-text">
                        <div>
                            2.&nbsp;
                        </div>
                        <div>
                            Then click 'Open in Safari'
                        </div>
                    </div>
                    <img className="ar-fallback-incompatible-browser-img" src={IncompatibleBrowserStep2} alt="incompatible-browser-step2" />
                </div>
            </div>
        </div>
    );
};

export default withRouter(IncompatibleBrowserModal);
