import React from "react";
import {withRouter} from "react-router-dom";
import Lottie from "react-lottie";
import "./fallback.css";
import * as animationData from "../../assets/lottie/anim_arFallback.json";
import Button from "../Button/Button";

const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const Fallback = (props) => {
    return (
        <div className="ar-fallback">
            <div className="ar-fallback-inner">
                <div className="approval-ar-fallback-close-container">
                    <div style={{ display: "flex" }} />
                    <div className="approval-ar-fallback-close">
                    </div>
                </div>
                <div className="ar-fallback-content-container">
                    <Lottie
                        options={lottieOptions}
                        height={128}
                        width={176}
                        style={{ margin: 0, padding: 0 }}
                    />
                    <div className="ar-fallback-title">Device not supported</div>

                </div>
                <div className="ar-fallback-button-container">
                    <Button
                        loadingText={"Loading..."}
                        title={"Go back"}
                        width={props.width}
                        hasLoaded={true}
                        approvalButton
                        onClick={() => props.history.goBack()}
                    />
                </div>
            </div>
        </div>
    );
};

export default withRouter(Fallback);
