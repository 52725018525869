import React from "react";
import "./style.css";
import { ReactComponent as ArIcon } from "../../assets/svg/AR_icon.svg";
import labels from "../../i18n/labels";
import useQuery from "../../util/useQuery";

const NotFound = (props) => {
    const langParam = useQuery().get("lang");

    return (
        <div className="not-found-container">
            <ArIcon width={48} height={48} />
            <p className="not-found-hero-text">
                404
            </p>
            <p className="not-found-prompt">
                {labels.notFoundText.hasOwnProperty(langParam) ? labels.notFoundText[langParam] : labels.notFoundText["en"]}
            </p>
        </div>
    );
};

export default NotFound;
