/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getUA, isIOS, isSafari } from "react-device-detect";
import Root from "./components/Root/Root";
import { LOCATION, RESIZE } from "./constants/crossDomainMessages";
import VisibilityManager from "./components/VisibilityManager/VisibilityManager";
import Fallback from "./components/Fallback/Fallback";
import DeviceNotSupportedModal from "./components/Modal/DeviceNotSupportedModal";
import IncompatibleBrowserModal from "./components/Modal/IncompatibleBrowserModal";
import ThreeDimViewModal from "./components/Modal/ThreeDimViewModal";
import Content from "./components/Content/Content";
import SelfContained from "./components/SelfContained/SelfContained";
import NotFound from "./components/NotFound/NotFound";

function App() {
  const [frameLocation, setFrameLocation] = useState("");

  const [notFbBrowser, setNotFbBrowser] = useState(true);

  const initHeight = window.innerHeight;
  const initWidth = window.innerWidth;

  const [parentWindow, setParentWindow] = useState();

  const [frameWidth, setFrameWidth] = useState(initWidth);
  const [frameHeight, setFrameHeight] = useState(initHeight);

  const listenIncomingMessages = (event) => {
    const data = JSON.parse(event.data);
    switch (data.type) {
      case LOCATION:
        setFrameLocation(document.referrer);
        setParentWindow(data.window);
        break;
      case RESIZE:
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        setFrameWidth(newWidth);
        setFrameHeight(newHeight);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      isIOS && !isSafari ||
        // eslint-disable-next-line
      (getUA.includes("FBAN") ||
        getUA.includes("FBAV") ||
        getUA.includes("Instagram") ||
        getUA.includes("instagram") ||
        getUA.includes("LinkedIn"))
    ) {
      setNotFbBrowser(false);
    }
    window.addEventListener("message", listenIncomingMessages, false);

    // returned function will be called on component unmount
    return () => {
      window.addEventListener("message", listenIncomingMessages, false);
    };
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          path="/products/:id"
          children={
            <VisibilityManager>
              {isVisible =>
                  <Root
                      isVisible={isVisible}
                      parentWindow={parentWindow}
                      frameLocation={frameLocation}
                      width={frameWidth}
                      height={frameHeight}
                      notFbBrowser={notFbBrowser}
                  />}
            </VisibilityManager>

          }
        />
        <Route path="/content/:id" children={<Content />} />
        <Route path="/embed/:id" children={<SelfContained />} />
        <Route path="/not-available" children={<Fallback width={frameWidth} />} />
        <Route path="/device-not-supported" children={<DeviceNotSupportedModal width={frameWidth} />} />
        <Route path="/incompatible-browser" children={<IncompatibleBrowserModal width={frameWidth} />} />
        <Route path="/object-model" children={<ThreeDimViewModal width={frameWidth} />} />
        <Route path="*" children={<NotFound width={frameWidth} />} />
      </Switch>
    </Router>
  );
}

export default App;
