import http from "../util/http";
import { PRODUCTS } from "../constants/apiUrls";

const getProductById = (id) => {
  return http
    .get(`${PRODUCTS}${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export default getProductById;
