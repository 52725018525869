import React from "react";
import {withRouter} from "react-router-dom";
import Lottie from "react-lottie";
import "./deviceNotSupportedModal.css";
import * as animationData from "../../assets/lottie/anim_arFallback.json";
import Button from "../Button/Button";
import {ReactComponent as CloseLogo} from "../../assets/svg/ARwidget_ic_close.svg";

const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const DeviceNotSupportedModal = (props) => {
    const { onClose, id } = props;
    return (
        <div className="ar-fallback">
            <div className="ar-fallback-inner">
                <div className="ar-fallback-close-container">
                    <div style={{ display: "flex" }} />
                    <div className="ar-fallback-close">
                        <CloseLogo width="48" height="48" onClick={onClose} />
                    </div>
                </div>
                <div className="ar-fallback-content-container">
                    <Lottie
                        options={lottieOptions}
                        height={128}
                        width={176}
                        style={{ margin: 0, padding: 0 }}
                    />
                    <div className="ar-fallback-title">Device not supported</div>
                    <div className="ar-modal-help">
                        <a
                            href={"https://lab.mobi/mars/experience-in-reality/wizard#supported-devices"}
                            // eslint-disable-next-line
                            target="_blank"
                            className="ar-modal-help-text"
                        >
                            {"See supported devices"}
                        </a>
                    </div>
                </div>
                <div className="ar-fallback-button-container">
                    <Button
                        loadingText={"Loading..."}
                        title={"Open 3D model"}
                        width={props.width}
                        hasLoaded={true}
                        approvalButton
                        navigationButton
                        onClick={()=> {
                            props.history.push(`/object-model?id=${id}`);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default withRouter(DeviceNotSupportedModal);
