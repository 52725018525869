export default {
    et: {
        buttonText:"Vaatle liitreaalsuses",
        fallbackButtonText:"Tagasi",
        scanHelperText:"Kuidas skannida",
        qrPromptText:"Skannige kood oma nutitelefoni kaamerarakendusega",
        howItWorksText:"Kuidas see töötab!",
        arWizardLink:"https://lab.mobi/ar-wizard-ee",
        arHelpLink:"https://lab.mobi/ar-help-ee",
        loadingText:"Laeb...",
        makeSureToTurnOnSound:"Lülitage kindlasti heli sisse",
        open3dObject:"Vajuta nuppu liitreaalsuse avamiseks"
    },
    en: {
        buttonText:"Experience in reality",
        fallbackButtonText:"Go back",
        scanHelperText:"Learn how to scan",
        qrPromptText:"Scan the code with your mobile phone camera application",
        howItWorksText:"How it works!",
        arWizardLink:"https://lab.mobi/ar-wizard",
        arHelpLink:"https://lab.mobi/ar-help",
        loadingText: "Loading...",
        makeSureToTurnOnSound:"Make sure to turn on the sound",
        open3dObject:"Click the button to open 3D object"
    }
}

